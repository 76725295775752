import React, { Component } from "react";
import ContactInfo from "./ContactInfo";
import AddressInfo from "./AddressInfo";
import SocialIconsContainer from "./SocialIconsContainer";
import { css } from "glamor";

class Footer extends Component {
  render() {
    let footerCss = css({
      backgroundColor: "#414042",
      padding: "10px 0px",
      fontSize: "0.8em",
      lineHeight: "1.8em",
      color: "white"
    });

    let footerContainerCss = css(
      {
        maxWidth: "875px",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundImage:
          "url(" + process.env.PUBLIC_URL + "/images/logo_white_text.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "144px 144px"
      },
      {
        "@media(max-width: 900px)": {
          paddingLeft: "20px",
          paddingRight: "20px"
        }
      },
      {
        "@media(max-width: 640px)": {
          textAlign: "center",
          backgroundImage: "none"
        }
      }
    );

    let copyrightCss = css({
      textAlign: "center",
      fontSize: "0.75em"
    });

    var currentYear = new Date().getFullYear();
    if (currentYear !== 2020) {
      currentYear = "2020 - " + currentYear;
    }

    return (
      <footer>
        <div className={footerCss}>
          <div className={footerContainerCss}>
            <SocialIconsContainer />
            <AddressInfo />
            <ContactInfo />
          </div>
        </div>
        <p className={copyrightCss}>
          &copy; Copyright {currentYear} Pieter Van Damme
        </p>
      </footer>
    );
  }
}

export default Footer;
