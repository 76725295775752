import React, { Component } from "react";
import { SocialIcon } from "react-social-icons";
import { css } from "glamor";

class SocialIconLink extends Component {
  render() {
    let iconCss = css({
      marginLeft: 8
    });
    return (
      <span className={iconCss}>
        <SocialIcon
          url={this.props.url}
          color="white"
          style={{ height: 44, width: 44 }}
        />
      </span>
    );
  }
}

export default SocialIconLink;
