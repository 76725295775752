import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { css } from "glamor";

class HeaderLink extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(event) {
    var targetChildren = event.target.children;
    if (targetChildren && targetChildren.length > 0) {
      var firstChild = targetChildren[0];
      window.location.href = firstChild.href;
    }
    this.props.action();
  }

  render() {
    let liCss = css(
      {
        display: "inline-block",
        marginLeft: 0,
        color: "#666",
        height: "100px",
        lineHeight: "44px",
        padding: "0 16px",
        fontSize: "1.4em",
        fontFamily: "'Myriad Pro Condensed', sans-serif",
        textTransform: "lowercase",
        ":hover": {
          backgroundColor: "rgba(255, 255, 255, 0.15)",
          color: "white"
        },
        "& .active": {
          textDecoration: "none",
          color: "white"
        }
      },
      {
        "@media(max-width: 900px)": {
          display: "block",
          width: "100vw",
          textAlign: "center",
          lineHeight: "64px",
          padding: 0,
          borderBottom: "1px solid #666",
          fontSize: "1.5em",
          height: 64
        }
      }
    );

    let aCss = css(
      {
        textDecoration: "none",
        fontWeight: "normal",
        color: "rgb(128, 128, 128)"
      },
      {
        "@media(max-width: 900px)": {
          lineHeight: "44px"
        }
      }
    );

    return (
      <li onClick={this.handleClick} className={liCss}>
        <NavLink
          activeClassName="active"
          exact={this.props.exact}
          to={this.props.url}
          className={`${aCss}`}
        >
          {this.props.label}
        </NavLink>
      </li>
    );
  }
}

export default HeaderLink;
