import React, { Component } from "react";
import HeaderLink from "./HeaderLink";
import { css } from "glamor";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { tocIsHidden: true };
    this.toggleToc = this.toggleToc.bind(this);
  }
  toggleToc() {
    const { tocIsHidden } = this.state;
    this.setState({
      tocIsHidden: !tocIsHidden
    });
    return false;
  }

  render() {
    let headerCss = css(
      {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "144px",
        backgroundColor: "#414042",
        zIndex: 1200,
        cursor: "pointer"
      },
      {
        "@media(max-width: 900px)": {
          height: 100
        }
      },
      {
        "@media(max-width: 640px)": {
          height: "64px",
          backgroundSize: "50px auto"
        }
      }
    );

    let headerWrapperCss = css(
      {
        width: "875px",
        margin: "0 auto",
        backgroundImage:
          "url(" + process.env.PUBLIC_URL + "/images/logo_white.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left center",
        backgroundSize: "64px 64px"
      },
      {
        "@media(max-width: 900px)": {
          backgroundPosition: "20px center",
          height: 100,
          paddingLeft: "20px",
          paddingRight: "20px"
        }
      },
      {
        "@media(max-width: 640px)": {
          height: 64,
          backgroundSize: "44px 44px"
        }
      }
    );

    let headerLinksWrapperCss = css(
      {
        height: 44,
        borderTop: "1px solid #666"
      },
      {
        "@media(max-width: 900px)": {
          height: 0
        }
      },
      {
        "@media(max-width: 640px)": {
          height: 64
        }
      }
    );

    let headerH1Css = css(
      {
        position: "relative",
        top: 0,
        left: 80,
        height: "80px",
        lineHeight: "80px",
        margin: 0,
        padding: 0,
        fontSize: "2em",
        color: "white",
        fontWeight: "bold",
        maxWidth: "450px"
      },
      {
        "@media(max-width: 640px)": {
          lineHeight: "70px",
          left: "54px",
          fontSize: "1.2em"
        }
      }
    );

    let headerH2Css = css(
      {
        position: "relative",
        left: 80,
        top: -20,
        fontSize: "1em",
        lineHeight: "1",
        textTransform: "none",
        color: "white"
      },
      {
        "@media(max-width: 640px)": {
          display: "none"
        }
      }
    );

    let iconTocCss = css(
      {
        position: "absolute",
        top: "0",
        right: "10px",
        margin: "0px",
        padding: "0px",
        paddingRight: "10px",
        cursor: "pointer",
        backgroundImage: this.state.tocIsHidden
          ? "url(" + process.env.PUBLIC_URL + "/images/icon_toc.png)"
          : "url(" + process.env.PUBLIC_URL + "/images/icon_close.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "44px 44px",
        height: "100px",
        width: "44px",
        display: "none",
        zIndex: 1111
      },
      {
        "@media(max-width: 900px)": {
          display: "inline"
        }
      },
      {
        "@media(max-width: 640px)": {
          height: "64px",
          width: "44px",
          paddingRight: 0
        }
      }
    );

    let headerUlCss = css(
      {
        width: "875px",
        margin: "0 auto",
        padding: 0,
        paddingRight: "10px",
        overflow: "hidden",
        height: 44,
        textAlign: "left"
      },
      {
        "@media(max-width: 900px)": {
          display: this.state.tocIsHidden ? "none" : "inline",
          position: "fixed",
          width: "100vw",
          height: "100vh",
          left: 0,
          top: "100px",
          paddingTop: "0px",
          zIndex: 999,
          backgroundColor: "#414042",
          overflow: "auto",
          borderTop: "1px solid #666"
        }
      },
      {
        "@media(max-width: 640px)": {
          top: "64px",
          paddingTop: "0px"
        }
      }
    );

    return (
      <header className={headerCss}>
        <div className={headerWrapperCss}>
          <h1 className={headerH1Css}>
            <a href="/" style={{ color: "white" }}>
              {this.props.title}
            </a>
          </h1>
          <h2 className={headerH2Css}>
            vloer- en tegelwerken, terrassen en opritten, natuursteen
          </h2>
        </div>
        <div className={iconTocCss} onClick={this.toggleToc} />
        <div className={headerLinksWrapperCss}>
          <ul className={headerUlCss}>
            <HeaderLink action={this.toggleToc} exact url="/" label="Home" />
            <HeaderLink
              action={this.toggleToc}
              url="/over-mezelf"
              label="Over Mezelf"
            />
            <HeaderLink action={this.toggleToc} url="/fotos" label="Foto's" />
            <HeaderLink action={this.toggleToc} url="/minerale-stuc" label="Minerale Stuc" />
            <HeaderLink
              action={this.toggleToc}
              url="/contact"
              label="Contact"
            />
          </ul>
        </div>
      </header>
    );
  }
}

export default Header;
