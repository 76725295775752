import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import ScrollUp from "react-scroll-up";
import MetaTags from "react-meta-tags";
import * as Constants from "../Constants";
import { css } from "glamor";

class Page extends Component {
  componentDidMount() {}

  render() {
    var title = this.props.title;

    var shortTitle = this.props.shortTitle;
    if (!shortTitle) {
      shortTitle = title;
    }
    shortTitle += " | " + Constants.siteName;

    var width = this.props.width !== undefined ? this.props.width : "875px";
    var margin = this.props.margin !== undefined ? this.props.margin : "auto";

    let upStyle = {
      position: "fixed",
      bottom: 20,
      right: 20,
      width: "44px",
      height: "44px",
      backgroundColor: "#222",
      color: "white",
      textAlign: "center",
      lineHeight: "44px"
    };

    let wrapperCss = css();
    if (this.props.width === "100%") {
      wrapperCss = css(
        {
          height: "100%",
          margin: "164px auto 20px auto"
        },
        {
          "@media(max-width: 875px)": {
            margin: "124px auto 20px auto"
          }
        },
        {
          "@media(max-width: 640px)": {
            marginTop: "84px",
            marginBottom: "10px"
          }
        }
      );
    } else {
      wrapperCss = css(
        {
          height: "100%",
          margin: "164px auto 20px auto"
        },
        {
          "@media(max-width: 875px)": {
            margin: "124px auto 20px auto",
            paddingLeft: "20px",
            paddingRight: "20px"
          }
        },
        {
          "@media(max-width: 640px)": {
            marginTop: "84px",
            marginBottom: "10px"
          }
        }
      );
    }

    return (
      <DocumentTitle title={shortTitle}>
        <div>
          <MetaTags>
            <meta property="og:type" content="website" />
          </MetaTags>
          <div
            className={wrapperCss}
            style={{
              maxWidth: width,
              marginLeft: margin,
              marginRight: margin
            }}
          >
            {title && <h1>{title}</h1>}
            {this.props.children}
          </div>
          <ScrollUp showUnder={160} style={upStyle}>
            <span>↑</span>
          </ScrollUp>
        </div>
      </DocumentTitle>
    );
  }
}

export default Page;
