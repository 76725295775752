import React, { Component } from "react";
import Page from "../components/Page";
import { css } from "glamor";

class MineraleStuc extends Component {

    constructor() {
        super();
        this.onContactMe = this.onContactMe.bind(this);
    }
    
    onContactMe() {
        window.location.href = "/contact";
    }
    
    render() {

        let containerCss = css({
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between"
        });

        let itemLeftCss = css(
            {
                flex: "0 1 auto",
                width: "calc(50% - 1em)"
            },
            {
                "@media(max-width: 640px)": {
                    flex: "0 1 auto",
                    width: "100%"
                }
            }
        );

        let itemRightCss = css(
            {
                flex: "0 1 auto",
                width: "calc(50% - 1em)"
            },
            {
                "@media(max-width: 640px)": {
                    flex: "0 1 auto",
                    width: "100%"
                }
            }
        );

        let imagePCss = css({
            textAlign: "center",
        });

        let imageCss = css({
            minWidth: "100%",
            maxWidth: "100%",
        });

        let buttonCss = css({
            letterSpacing: 1,
            marginBottom: 64,
            color: "white",
            height: "44px",
            backgroundColor: "#414042",
            border: 0,
            textAlign: "center",
            lineHeight: "44px",
            cursor: "pointer",
            maxWidth: 240,
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "30px"
        });

        
        let linkCss = css({
            textDecoration: "none",
            textTransform: "uppercase",
            color: "white"
        });
  
        return (
            <Page title="Minerale Stuc / Gazelle Ombra" shortTitle="Minerale Stuc">

                <p className={imagePCss}><img src={process.env.PUBLIC_URL + "/images/covers/minerale-stuc-01.jpg"} className={imageCss} alt="" /></p>
                
                <p>Gazelle Ombra is een nobel, duurzaam, watervast en zeer flexibel stuc materiaal met uitstraling.</p>
                <p>Het uitgebreide kleurenpalet maakt deze minerale stuc hét uitgesproken materiaal voor decorateurs in interieurarchitecten in nieuwbouw en renovatie projecten.</p>
                <p>Gazelle Ombra is van nature niet glad. Optioneel kan een antislip coating bekomen worden voor hellingen, zwembaden, toiletvloeren, douchevloer, …</p>
                <p>Gazelle Ombra is uitstekend toe te passen op keuken- en badkamerwerkbladen.</p>
                <p>Zijn samenstelling maakt het materiaal zeer geschikt voor natte omgevingen.</p>

                <div className={containerCss}>
                    <div className={itemLeftCss}>
        
                        <h2>Eigenschappen</h2>

                        <p className={imagePCss}><img src={process.env.PUBLIC_URL + "/images/covers/minerale-stuc-02.jpg"} className={imageCss} alt="" /></p>

                        <ul>
                            <li><p>Optimale hechting op bestaande materialen: terrazzo, marmer, keramiek, beton en geperst plaatmateriaal.</p></li>
                            <li><p>De geringe dikte van <nobr>3 mm</nobr> aangebracht in 4 lagen en de versterkte beschermlaag voor makkelijk onderhoud.</p></li>
                            <li><p>Plaatsbaar zonder voegen en past in elke interieurstijl: modern, rustiek, minimalistisch…</p></li>
                        </ul>
                    </div>
                    <div className={itemRightCss}>

                        <h2>Plaatsing en afwerking</h2>

                        <p className={imagePCss}><img src={process.env.PUBLIC_URL + "/images/minerale-stuc-03.jpg"} className={imageCss} alt="" /></p>

                        <ul>
                            <li><p>Uistekend toepasbaar in renovatie zonder breekwerk, structurele aanpassingen of lawaaihinder.</p></li>
                            <li><p>Snel drogend en snel beloopbaar.</p></li>
                            <li><p>Dit materiaal staat bekend om zijn hoge slijtweerstand, makkelijk onderhoud en reiniging.</p></li>
                            <li><p>Verkrijgbaar in 4 afwerkingen: extra mat, mat, satijn of glans.</p></li>
                            <li><p>Gamma van 42 kleuren.</p></li>
                        </ul>

                    </div>
                </div>

                <p className={buttonCss} onClick={this.onContactMe}>
                    <a href="/contact" className={linkCss}>
                        Contacteer me voor info!
                    </a>
                </p>

            </Page>
        );
  
    }

}

export default MineraleStuc;
