import React, { Component } from "react";
import Page from "../components/Page";
import { css } from "glamor";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      photos: [],
      photo: undefined
    };
    this.onContactMe = this.onContactMe.bind(this);
  }

  componentDidMount() {
    fetch(process.env.PUBLIC_URL + "/images/covers.json")
      .then(response => {
        return response.json();
      })
      .then(photos => {
        var photo = photos[Math.floor(Math.random() * photos.length)];
        this.setState({
          photos: photos,
          photo: photo.src
        });
      });
  }

  onContactMe() {
    window.location.href = "/contact";
  }

  render() {
    let homeImageCss = css(
      {
        maxWidth: 875,
        width: "100%",
        height: 360,
        marginBottom: "1em",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundImage: "url(" + this.state.photo + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        position: "relative"
      }
    );

    let detailCss = css(
      {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        fontSize: "1.5em",
        fontFamily: '"Myriad Pro Light Condensed", sans-serif',
        textTransform: "uppercase",
        letterSpacing: "1px",
        textAlign: "center",
        width: "100%",
        paddingTop: "10px",
        paddingBottom: "10px",
        color: "white",
        lineHeight: "1.5em",
        backgroundColor: "rgba(0, 0, 0, 0.5)"
      },
      {
        "@media(max-width: 875px)": {
          fontSize: "1.25em",
        }
      }
    );

    let buttonCss = css({
      letterSpacing: 1,
      marginBottom: 64,
      color: "white",
      height: "44px",
      backgroundColor: "#414042",
      border: 0,
      textAlign: "center",
      lineHeight: "44px",
      cursor: "pointer",
      maxWidth: 240,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "30px"
    });

    let maxWidthCss = css({
      maxWidth: 480,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto"
    });

    let linkCss = css({
      textDecoration: "none",
      textTransform: "uppercase",
      color: "white"
    });

    let containerBackground = css(
      {
        backgroundColor: "#CCC",
        marginTop: 64,
        paddingTop: 44,
        paddingBottom: 44
      },
      {
        "@media(max-width: 875px)": {
          paddingTop: 22,
          paddingBottom: 22
        }
      }
    );

    let containerCss = css({
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      maxWidth: 875,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto"
    });

    let itemLeftCss = css(
      {
        flex: "0 1 auto",
        width: "calc(28% - 1em)",
        textAlign: "center"
      },
      {
        "@media(max-width: 875px)": {
          paddingLeft: "20px"
        }
      },
      {
        "@media(max-width: 640px)": {
          flex: "0 1 auto",
          width: "100%",
          paddingLeft: "20px",
          paddingRight: "20px",        }
      }
    );

    let itemRightCss = css(
      {
        flex: "0 1 auto",
        width: "calc(68% - 1em)"
      },
      {
        "@media(max-width: 875px)": {
          paddingRight: "20px"
        }
      },
      {
        "@media(max-width: 640px)": {
          flex: "0 1 auto",
          width: "100%",
          paddingLeft: "20px",
          paddingRight: "20px",
        }
      }
    );

    let imageCss = css(
      {
        minWidth: "100%",
        maxWidth: "100%",
        marginTop: "16px",
      },
      {
        "@media(max-width: 640px)": {
          maxWidth: "50%",
          minWidth: "50%"
        }
      }
    );

    return (
      <Page title="" shortTitle="Home" width="100%">
        <div style={{ paddingTop: 44 }}>
          <div className={homeImageCss}>
            <div className={detailCss}>
              <nobr>Vloeren &amp; Tegels</nobr> •{" "}
              <nobr>Terrassen &amp; opritten</nobr> • Badkamers • Chapewerk • <nobr>Minerale Stuc</nobr>
            </div>
          </div>
        </div>

        <div className={containerBackground}>
          <div className={containerCss}>
            <div className={itemLeftCss}>
              <img
                alt="Pieter Van Damme"
                src={process.env.PUBLIC_URL + "/images/pietervandamme.jpg"}
                className={imageCss}
              />
            </div>
            <div className={itemRightCss}>
              <p>Mijn naam is Pieter Van Damme.</p>
              <p>
                Mijn pa was zelfstandig vloerder en elke vrije minuut ging ik
                met hem mee. De keuze om hierin verder te gaan lag dan ook voor
                de hand want ik had de microbe voor “de bouw” écht te pakken.
              </p>

              <p>
                Na mijn schoolcarrière werkte ik 15 jaar in loondienst, een
                echte must want daar leerde ik de knepen van het vak.
              </p>

              <p>
                Na 2 jaar in bijberoep durfde ik eindelijk de stap te nemen om
                helemaal zelfstandig te worden.
              </p>

              <p>
                Bij mij kan je terecht voor vloerwerken en chapewerken of de
                aanleg van opritten, terrassen en wandtegels. Ik ben thuis in
                elk type woning. Van kleine verbouwing tot totaalproject.
              </p>

              <p>
                Voor grotere realisaties werk ik graag samen met{" "}
                <a
                  href="https://www.jakonstrukt.be"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <nobr>JA Konstrukt</nobr>
                </a>{" "}
                en{" "}
                <a
                  href="https://sanitom.be"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SaniTom
                </a>
                . Zo ben je altijd zeker van een goede prijs en kwaliteit.
              </p>
            </div>
          </div>
        </div>

        <div className={maxWidthCss}>
          <h2 style={{ textAlign: "center", paddingTop: 64 }}>
            Ik kijk al uit naar jouw project!
          </h2>
          <p style={{ textAlign: "center" }}>
            Om een realistische offerte te maken, kom ik ter plaatse om naar je
            wensen te luisteren en professioneel advies te geven.
          </p>
          <p className={buttonCss} onClick={this.onContactMe}>
            <a href="/contact" className={linkCss}>
              Contacteer me
            </a>
          </p>
        </div>
      </Page>
    );
  }
}

export default Home;
