import React, { Component } from "react";
import { css } from "glamor";

class AddressInfo extends Component {
  render() {
    let nameCss = css(
      {
        fontWeight: "bold"
      },
      {
        "@media(max-width: 640px)": {
          fontWeight: "bold"
        }
      }
    );

    let footerDescription = css(
      {
        display: "none"
      },
      {
        "@media(max-width: 640px)": {
          display: "block"
        }
      }
    );

    return (
      <p>
        <nobr className={nameCss}>Pieter Van Damme</nobr>
        <br />
        <span className={footerDescription}>
          vloer- en tegelwerken, terrassen en opritten, natuursteen
          <br />
        </span>
        <nobr>Mouterijstraat 29</nobr>
        <br />
        <nobr>8730 Sint-Joris (Beernem)</nobr>
        <br />
        <nobr class="vat-number">BTW BE 0751 970 526</nobr>
      </p>
    );
  }
}

export default AddressInfo;
