import React from "react";
// import ReactDOM from "react-dom";
import App from "./components/App";
// import ReactGA from "react-ga";
import { BrowserRouter } from "react-router-dom";
import { hydrate, render } from "react-dom";

import "./index.css";

// ReactGA.initialize("UA-120864642-1");

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    rootElement
  );
} else {
  render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    rootElement
  );
}
