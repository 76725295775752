import React, { Component } from "react";

class ContactInfo extends Component {
  render() {
    return (
      <p>
        <nobr>
          Telefoon:{" "}
          <a href="callto:+32472983511" style={{ color: "white" }} class="phone">
            +32 472 983 511
          </a>
        </nobr>
        <br />
        <nobr>
          Email:{" "}
          <a
            href="mailto:pieter-vandamme@telenet.be"
            style={{ color: "white" }}
          >
            pieter-vandamme@telenet.be
          </a>
        </nobr>
      </p>
    );
  }
}

export default ContactInfo;
