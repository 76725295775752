import React from "react";
import { Switch, Route } from "react-router-dom";
import Page from "./Page";
import Home from "../views/Home";
import Photos from "../views/Photos";
import MineraleStuc from "../views/MineraleStuc";
import OverMezelf from "../views/OverMezelf";
import Contact from "../views/Contact";

const NoMatch = ({ location }) => (
  <Page title="404 | Oeps, niet gevonden">
    <p>We konden niets vinden voor deze URL:</p>
    <p>
      <code>{location.pathname}</code>
    </p>
    <p>
      <a href="/">Ga terug naar de homepage</a>
    </p>
  </Page>
);

const Main = () => (
  <main>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/fotos" component={Photos} />
      <Route path="/over-mezelf" component={OverMezelf} />
      <Route path="/minerale-stuc" component={MineraleStuc} />
      <Route path="/contact" component={Contact} />
      <Route component={NoMatch} />
    </Switch>
  </main>
);

export default Main;
