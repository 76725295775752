import React, { Component } from "react";
import Page from "../components/Page";
import LightboxGallery from "../components/LightboxGallery";

class Photos extends Component {
  constructor() {
    super();
    this.state = {
      badkamers: [],
      chape: [],
      vloeren: [],
      terrassen: []
    };
  }

  componentDidMount() {
    fetch(process.env.PUBLIC_URL + "/images/fotos.json")
      .then(response => {
        return response.json();
      })
      .then(photos => {
        this.setState({
          badkamers: photos["badkamers"],
          chape: photos["chape"],
          vloeren: photos["vloeren"],
          terrassen: photos["terrassen"]
        });
      });
  }

  render() {
    return (
      <Page title="Foto's" shortTitle="Foto's">
        <h2>Vloeren &amp; Tegels</h2>
        <LightboxGallery photos={this.state.vloeren} />
        <h2>
          &nbsp;
          <br />
          Terrassen &amp; Opritten
        </h2>
        <LightboxGallery photos={this.state.terrassen} />
        <h2>
          &nbsp;
          <br />
          Badkamers
        </h2>
        <LightboxGallery photos={this.state.badkamers} />
        <h2>
          &nbsp;
          <br />
          Chapewerk
        </h2>
        <LightboxGallery photos={this.state.chape} />
      </Page>
    );
  }
}

export default Photos;
