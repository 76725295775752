import React, { Component } from "react";
import Page from "../components/Page";
import { css } from "glamor";

class OverMezelf extends Component {
  render() {
    let containerCss = css({
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between"
    });

    let itemLeftCss = css(
      {
        flex: "0 1 auto",
        width: "calc(50% - 1em)"
      },
      {
        "@media(max-width: 800px)": {
          flex: "0 1 auto",
          width: "calc(30% - 1em)"
        }
      },
      {
        "@media(max-width: 640px)": {
          flex: "0 1 auto",
          width: "100%"
        }
      }
    );

    let itemRightCss = css(
      {
        flex: "0 1 auto",
        width: "calc(50% - 1em)"
      },
      {
        "@media(max-width: 800px)": {
          flex: "0 1 auto",
          width: "calc(70% - 1em)"
        }
      },
      {
        "@media(max-width: 640px)": {
          flex: "0 1 auto",
          width: "100%"
        }
      }
    );

    let imageCss = css({
      minWidth: "100%",
      maxWidth: "100%",
      marginTop: "16px"
    });

    return (
      <Page title="Over Mezelf" shortTitle="Over Mezelf">
        <div className={containerCss}>
          <div className={itemLeftCss}>
            <img
              alt="Pieter Van Damme"
              src={process.env.PUBLIC_URL + "/images/pietervandamme.jpg"}
              className={imageCss}
            />
          </div>
          <div className={itemRightCss}>
            <p>Mijn naam is Pieter Van Damme.</p>
            <p>
              Ik ben papa van Ieme en Jitte, echtgenoot van Tamara en vloerder
              in hart en nieren.
            </p>

            <p>
              Mijn pa was zelfstandig vloerder en elke vrije minuut ging ik met
              hem mee. De keuze om hierin verder te gaan lag dan ook voor de
              hand want ik had de microbe voor “de bouw” écht te pakken.
            </p>

            <p>
              Na mijn schoolcarrière werkte ik 15 jaar in loondienst, een echte
              must want daar leerde ik de knepen van het vak.
            </p>

            <p>
              Na 2 jaar in bijberoep durfde ik eindelijk de stap te nemen om
              helemaal zelfstandig te worden.
            </p>

            <p>
              Bij mij kan je terecht voor vloerwerken en chapewerken of de
              aanleg van opritten, terrassen en wandtegels. Ik ben thuis in elk
              type woning. Van kleine verbouwing tot totaalproject.
            </p>

            <p>
              Voor grotere realisaties werk ik graag samen met{" "}
              <a
                href="https://www.jakonstrukt.be"
                target="_blank"
                rel="noopener noreferrer"
              >
                <nobr>JA Konstrukt</nobr>
              </a>{" "}
              en{" "}
              <a
                href="https://sanitom.be"
                target="_blank"
                rel="noopener noreferrer"
              >
                SaniTom
              </a>
              . Zo ben je altijd zeker van een goede prijs en kwaliteit.
            </p>

            <p>
              Om een realistische offerte te maken, kom ik ter plaatse om naar
              je wensen te luisteren en professioneel advies te geven.
            </p>

            <p>Ik kijk al uit naar jouw project!</p>
          </div>
        </div>
      </Page>
    );
  }
}

export default OverMezelf;
