import React, { Component } from "react";
import Page from "../components/Page";

class Contact extends Component {
    render() {
        return (
            <Page title="Contacteer Me" shortTitle="Contact">
                <p>Ik kom steeds ter plaatse om te luisteren naar de wensen en geef professionele uitleg over hoe &amp; wat voor een eerlijke offerte.</p>
                <p>Je kan me contacteren via:</p>
                <p>
                    <nobr>
                        Telefoon:{" "}
                        <a href="callto:+32472983511" class="phone">
                            +32 472 983 511
                        </a>
                    </nobr>
                    <br />
                    <nobr>
                        Email: <a href="mailto:pieter-vandamme@telenet.be">pieter-vandamme@telenet.be</a>
                    </nobr>
                </p>
            </Page>
        );
    }
}

export default Contact;
